<!-- Main Banner -->
<div class="home-wrapper-area">
    <div class="home-slides owl-carousel owl-theme">
        <div class="main-slider-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-content">
                                    <h1>Expertise in Cloud Computing</h1>
                                    <p>Our team of certified experts can help you navigate the ever-changing world of cloud computing, from migration to optimization and beyond.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/" class="default-btn">Get Services</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-image"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-slider-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-content">
                                    <h1>Specializing in Cybersecurity Solutions</h1>
                                    <p>Protect your business from cyber threats with our comprehensive security services, including threat detection, incident response, and compliance</p>
                                    <div class="slider-btn">
                                        <a routerLink="/" class="default-btn">Get Services</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-image two"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       
        <div class="main-slider-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-content">
                                    <h1>Full-Service IT Consulting</h1>
                                    <p>From strategy to implementation, our team of experienced consultants can help you achieve your business goals through the use of technology.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/" class="default-btn">Get Services</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-image three"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-slider-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-content">
                                    <h1>Agile Development Methodology</h1>
                                    <p>Adopt Agile development methodology to increase productivity and reduce costs by delivering high-quality software in shorter timeframes.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/" class="default-btn">Get Services</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-image four"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-slider-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-content">
                                    <h1>IT Staffing and Recruitment</h1>
                                    <p>Find the right talent for your organization with our IT staffing and recruitment services, including contract, contract-to-hire, and direct placement options</p>
                                    <div class="slider-btn">
                                        <a routerLink="/" class="default-btn">Get Services</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="main-slider-image three"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-slider-shape">
        <div class="shape-1"><img src="assets/img/main-slider/slider-shape-1.png" alt="image"></div>
        <div class="shape-2"><img src="assets/img/main-slider/slider-shape-2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/main-slider/slider-shape-3.png" alt="image"></div>
        <div class="shape-4"><img src="assets/img/main-slider/slider-shape-4.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Features -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3><a routerLink="/services-details">Marketing Analysis</a></h3>
                    <p>Evaluating market trends to target audience to develop effective marketing strategies.</p>
                    <div class="number">
                        <span>1</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3><a routerLink="/services-details">Website Optimization</a></h3>
                    <p>Website optimization refers to making changes to a website to improve its user experience.</p>
                    <div class="number">
                        <span>2</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3><a routerLink="/services-details">Email Marketing</a></h3>
                    <p>Direct communication with target audience via electronic mail to promote products or services.</p>
                    <div class="number">
                        <span>3</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Promoting products, services, or brands using social media platforms to reach target audience.</p>
                    <div class="number">
                        <span>4</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- Services -->
<section class="services-area bg-fafafa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Services we provided</span>
            <h2>Our Digital Marketing Services</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-code"></i>
                    </div>
                    <h3><a routerLink="/services-details">Website Development</a></h3>
                    <p>Creating and designing website using HTML, CSS, JavaScript, and web development frameworks. Optimized for user experience.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-vector"></i>
                    </div>
                    <h3><a routerLink="/services-details">Web Design</a></h3>
                    <p>Creating visually appealing and user-friendly websites through layout, design, and programming.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Creating and promoting content on social media platforms to increase brand awareness and drive sales.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-search"></i>
                    </div>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Creating and distributing valuable, relevant content to attract and engage target audience and ultimately drive profitable customer action.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing-1"></i>
                    </div>
                    <h3><a routerLink="/services-details">Digital Media & PPC</a></h3>
                    <p>Maximize online visibility and conversions through targeted digital media campaigns and pay-per-click advertising.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cpu"></i>
                    </div>
                    <h3><a routerLink="/services-details">Technology</a></h3>
                    <p>Technology refers to the application of scientific knowledge for practical purposes and including the development of tools.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- About -->
<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp image-two"></div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>Our team of experts has extensive experience in AI and IT</h3>
                    <div class="bar"></div>
                    <strong>we are dedicated to delivering innovative solutions that drive business success.</strong>
                    <p>We offer a range of services, including IT consulting, staffing, and project management. Whether you are looking to implement a new AI system, optimize your current IT infrastructure, or find top talent for your organization, NOUVS-AI has the expertise and resources to help.</p>
                    <div class="about-btn">
                        <a routerLink="/about-1" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About -->
 
<!-- Industries -->
<section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries We Work For</span>
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Digital Marketing</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Banking Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>Education Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Consult</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>Entrepreneur</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-waiter"></i>
                    <h3>Restaurant</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Health Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-user"></i>
                    <h3>Social Network</h3>
                    <a routerLink="/"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Industries -->
 
<!-- Partner -->
<!-- <div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner -->