<!-- Footer -->
<div class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                    </div>
                    <p>Welcome to NOUVS-AI IT Consulting and Staffing, where we are committed to providing top-notch service to our clients in the field of Artificial Intelligence and Information Technology.</p>
                    <ul class="social">
                        <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Important Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/aboutUs">About Us</a></li>
                        <li><a routerLink="/portfolio-2">Portfolio</a></li>
                        <li><a routerLink="/services-2">Services</a></li>
                        <li><a routerLink="/blog-2">Blog</a></li>
                        <li><a routerLink="/ContactUs">Contact</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Featured Services</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Development</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">Support Engineer</a></li>
                        <li><a routerLink="/">Website Development</a></li>
                        <li><a routerLink="/">App Development</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Information</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-call"></i>
                            <span>Phone</span>
                            <a href="tel:882569756">+1(281) 653-6566</a>
                        </li>
                        <li>
                            <i class="flaticon-email-1"></i>
                            <span>Email</span>
                            <a href="mailto:hello@rainz.com">info@novusai.cloud</a>
                        </li>
                        <li>
                            <i class="flaticon-pin"></i>
                            <span>Address</span>
                            <a href="https://www.google.com/maps/@51.5287718,-0.2416804,11z" target="_blank">1910 Hyannis CT, McLean, VA 22102</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Novus-Ai cloud</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>
<!-- End Go Top -->