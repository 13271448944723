<!-- Navbar -->
<div class="navbar-area">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="image"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/aboutUs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" >Services <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/IT-Solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IT Solutions</a></li>

                                <li class="nav-item"><a routerLink="/DevelpmentServices" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Development Services</a></li>

                             </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Industries<i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/banking" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Banking</a></li>

                                <li class="nav-item"><a routerLink="/HelathCare" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Health Care</a></li>

                                <li class="nav-item"><a routerLink="/Telecom" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Telecom</a></li>

                                <li class="nav-item"><a routerLink="/Retail" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Retail</a></li>

                                <li class="nav-item"><a routerLink="/EnergyAndUtilities" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Energy & Utilities</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/Clients" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clients</a></li>
                        <li class="nav-item"><a routerLink="/Careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                        <li class="nav-item"><a routerLink="/ContactUs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>

                  
                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar -->