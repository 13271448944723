import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ErrorComponent } from './components/pages/error/error.component';


const routes: Routes = [
   
    { path: '', component: HomeThreeComponent },
    
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }